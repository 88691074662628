export const data = [
  {
    title: 'Flash Cards',
    github: 'https://github.com/spell-landon/flash-cards',
    link: 'https://spell-landon.github.io/flash-cards/',
    content:
      "Flash Cards is a browser-based game that tests your knowledge of a given subject. You will have a card that displays a question with answer options listed below. You have a scoreboard that keeps track of your current score and will display a 'winning' or 'losing' message at the end of the game.",
    images: {
      desktop: [
        {
          image: '/images/FlashCards/desktop/landing_light.png',
          text: 'Landing Page (Light) - Desktop',
        },
        {
          image: '/images/FlashCards/desktop/landing_dark.png',
          text: 'Landing Page (Dark) - Desktop',
        },
        {
          image: '/images/FlashCards/desktop/gameplay_light.png',
          text: 'Gameplay (Light) - Desktop',
        },
        {
          image: '/images/FlashCards/desktop/gameplay_dark.png',
          text: 'Gameplay (Dark) - Desktop',
        },
        {
          image: '/images/FlashCards/desktop/winning.png',
          text: 'Winning Page (Light) - Desktop',
        },
        {
          image: '/images/FlashCards/desktop/losing.png',
          text: 'Losing Page (Dark) - Desktop',
        },
      ],
      mobile: [
        {
          image: '/images/FlashCards/mobile/landing.png',
          text: 'Landing Page - Mobile',
        },
        {
          image: '/images/FlashCards/mobile/landing_dark.png',
          text: 'Landing Page (Dark) - Mobile',
        },
        {
          image: '/images/FlashCards/mobile/gameplay.png',
          text: 'Gameplay - Mobile',
        },
        {
          image: '/images/FlashCards/mobile/gameplay_dark.png',
          text: 'Gameplay (Dark) - Mobile',
        },
        {
          image: '/images/FlashCards/mobile/gameplay_selection.png',
          text: 'Gameplay - Answer Selected - Mobile',
        },
        {
          image: '/images/FlashCards/mobile/gameplay_selection_dark.png',
          text: 'Gameplay - Answer Selected (Dark) - Mobile',
        },
        {
          image: '/images/FlashCards/mobile/winning.png',
          text: 'Winning Page - Mobile',
        },
        {
          image: '/images/FlashCards/mobile/losing.png',
          text: 'Losing Page - Mobile',
        },
      ],
    },
  },
  {
    title: 'Recipeace',
    github: 'https://github.com/spell-landon/recipeace',
    link: 'https://recipeace.netlify.app/',
    content:
      'Recipeace is a single page application that lets hungry users find recipes on anything they are looking for. I utilized the Edamam API to procure data based on a search query, or by clicking the randomize button which will show random recipes.',
    images: {
      desktop: [
        {
          image: '/images/Recipeace/desktop/landing.png',
          text: 'Landing Page - Desktop',
        },
        {
          image: '/images/Recipeace/desktop/recipes.png',
          text: 'Recipes Page - Desktop',
        },
        {
          image: '/images/Recipeace/desktop/recipes_expanded.png',
          text: 'Recipes (Expanded) Page - Desktop',
        },
        {
          image: '/images/Recipeace/desktop/recipes_random.png',
          text: 'Recipes (Random) Page - Desktop',
        },
        {
          image: '/images/Recipeace/desktop/about.png',
          text: 'About Page - Desktop',
        },
      ],
      mobile: [
        {
          image: '/images/Recipeace/mobile/landing.png',
          text: 'Landing Page - Mobile',
        },
        {
          image: '/images/Recipeace/mobile/recipes.png',
          text: 'Recipes Page - Mobile',
        },
        {
          image: '/images/Recipeace/mobile/recipes_search.png',
          text: 'Recipes (Search) Page - Mobile',
        },
        {
          image: '/images/Recipeace/mobile/recipes_expanded.png',
          text: 'Recipes (Expanded) Page - Mobile',
        },
        {
          image: '/images/Recipeace/mobile/recipes_random.png',
          text: 'Recipes (Random) Page - Mobile',
        },
        {
          image: '/images/Recipeace/mobile/about.png',
          text: 'About Page - Mobile',
        },
      ],
    },
  },
  {
    title: 'The Watson',
    github: 'https://github.com/watson-project',
    link: 'https://the-watson.netlify.app/',
    content:
      "'The Watson' was built as a team project. It is a full MERN-stack, single page application, that utilizes React, MongoDB, Express, mongoose, Netlify, Heroku, Passport, JWT, and other libraries. The mantra is: 'The Watson is an open sourced, blog style website with support for text and images. Free from intrusive advertisements and clunky UI's, The Watson is the place where you can shout to the masses and be heard. Retaining the style of being open sourced, every article is able to be viewed, edited, and deleted by all logged in users. The Watson. Share the Future.",
    images: {
      desktop: [
        {
          image: '/images/TheWatson/desktop/landing.png',
          text: 'Landing Page - Desktop',
        },
        {
          image: '/images/TheWatson/desktop/login.png',
          text: 'Login Page - Desktop',
        },
        {
          image: '/images/TheWatson/desktop/register.png',
          text: 'Register Page - Desktop',
        },
        {
          image: '/images/TheWatson/desktop/article-feed.png',
          text: 'Article Feed (Dashboard) - Desktop',
        },
        {
          image: '/images/TheWatson/desktop/article.png',
          text: 'Article - Desktop',
        },
        {
          image: '/images/TheWatson/desktop/article-add.png',
          text: 'Article (Add) - Desktop',
        },
        {
          image: '/images/TheWatson/desktop/article-edit.png',
          text: 'Article (Edit) - Desktop',
        },
        {
          image: '/images/TheWatson/desktop/article-delete.png',
          text: 'Article (Delete) - Desktop',
        },
      ],
      mobile: [
        {
          image: '/images/TheWatson/mobile/landing.png',
          text: 'Landing Page - Mobile',
        },
        {
          image: '/images/TheWatson/mobile/login.png',
          text: 'Login Page - Mobile',
        },
        {
          image: '/images/TheWatson/mobile/register.png',
          text: 'Register Page - Mobile',
        },
        {
          image: '/images/TheWatson/mobile/article-feed.png',
          text: 'Article Feed (Dashboard) - Mobile',
        },
        {
          image: '/images/TheWatson/mobile/article.png',
          text: 'Article - Mobile',
        },
        {
          image: '/images/TheWatson/mobile/article-add.png',
          text: 'Article (Add) - Mobile',
        },
        {
          image: '/images/TheWatson/mobile/article-edit.png',
          text: 'Article (Edit) - Mobile',
        },
        {
          image: '/images/TheWatson/mobile/article-delete.png',
          text: 'Article (Delete) - Mobile',
        },
      ],
    },
  },
  {
    title: 'Petfindr',
    github: 'https://github.com/PetFindrSEI',
    link: 'https://petfindr.netlify.app/',
    content:
      'PetFindr was built as the final project during my 4 month course at the General Assembly Software Engineering Bootcamp. This was built using React for the frontend, and Django / PostgreSQL on the backend. Photo storage is handled by AWS and everything is deployed with Netlify and Heroku. Petfindr is the place to go for any pet whether lost or found. If you have lost a pet, you can browse our dashboard to see if anyone has posted about your lost furry friend! If they are reported as found, you can contact them right from the website! You can also post any pets that you have found to help reunite pets and owners!',
    images: {
      desktop: [
        {
          image: '/images/Petfindr/desktop/landing.png',
          text: 'Landing Page - Desktop',
        },
        {
          image: '/images/Petfindr/desktop/dashboard.png',
          text: 'Dashboard - Desktop',
        },
        {
          image: '/images/PetFindr/desktop/filter.png',
          text: 'Filter Results - Desktop',
        },
        {
          image: '/images/PetFindr/desktop/howitworks_menu.png',
          text: 'How It Works / Menu - Desktop',
        },
        {
          image: '/images/PetFindr/desktop/report_pet.png',
          text: 'Report a Pet - Desktop',
        },
      ],
      mobile: [
        {
          image: '/images/PetFindr/mobile/landing.png',
          text: 'Landing Page - Mobile',
        },
        {
          image: '/images/PetFindr/mobile/dashboard.png',
          text: 'Dashboard - Mobile',
        },
        {
          image: '/images/PetFindr/mobile/login.png',
          text: 'Login - Mobile',
        },
        {
          image: '/images/PetFindr/mobile/register.png',
          text: 'Register Account - Mobile',
        },
        {
          image: '/images/PetFindr/mobile/menu.png',
          text: 'Menu - Mobile',
        },
        {
          image: '/images/PetFindr/mobile/report.png',
          text: 'Report a Pet - Mobile',
        },
      ],
    },
  },
  {
    title: `Fat Daddy's BBQ`,
    github: 'https://github.com/spell-landon/fat_daddys_texas_bbq',
    link: 'https://fatdaddystexasbbq.netlify.app/',
    content: `This project initially started as something to practice coding back in March of 2021. My cousin said she and her husband were going to open a restaurant called "Fat Daddy's Texas BBQ" so I thought "Why not attempt to build them a website?". At that time, with beginners knowledge, I created everything with vanilla HTML, CSS, JS. After attending GA, I have learned a lot of new techniques that really cleaned up my code. I found this project again and decided it needed an overhaul!`,
    images: {
      desktop: [
        {
          image: '/images/FatDaddys/desktop/landing.png',
          text: 'Landing Page - Desktop',
        },
        {
          image: '/images/FatDaddys/desktop/menu.png',
          text: 'Food Menu - Desktop',
        },
        {
          image: '/images/FatDaddys/desktop/about.png',
          text: 'About Page - Desktop',
        },
      ],
      mobile: [
        {
          image: '/images/FatDaddys/mobile/landing.png',
          text: 'Landing Page - Mobile',
        },
        {
          image: '/images/FatDaddys/mobile/menu.png',
          text: 'Food Menu - Mobile',
        },
        {
          image: '/images/FatDaddys/mobile/about.png',
          text: 'About Page - Mobile',
        },
      ],
    },
  },
  {
    title: `ZOX`,
    github: null,
    link: 'https://zox.la',
    content: `Currently I'm here at ZOX! I have developed front-end components and pages utilizing Bit, TypeScript, TailwindCSS, Builder.io, and various other libraries/tools, while adhering to specified design requirements. A majority, I'd say approximately 90% of the front-end code for the new ZOX website, I have created. During this process, I have actively engaged in weekly L10 meetings, effectively utilizing JIRA for project management and documentation. Adapted seamlessly to the company's Git workflow and maintained a successful balance in a hybrid work schedule.`,
    images: {
      desktop: [
        {
          image: '/images/ZOX/desktop/landing.png',
          text: 'Landing Page - Desktop',
        },
        {
          image: '/images/ZOX/desktop/collection.png',
          text: 'Collection - Desktop',
        },
        {
          image: '/images/ZOX/desktop/product_page.png',
          text: 'Product Page - Desktop',
        },
        {
          image: '/images/ZOX/desktop/new_releases.png',
          text: 'New Releases Page - Desktop',
        },
        {
          image: '/images/ZOX/desktop/cart_flyout.png',
          text: 'Cart Flyout - Desktop',
        },
        {
          image: '/images/ZOX/desktop/cart_flyout_filled.png',
          text: 'Cart Flyout - Filled - Desktop',
        },
        {
          image: '/images/ZOX/desktop/cart_page.png',
          text: 'Cart Page - Desktop',
        },
      ],
    },
  },
];
